/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic {
	@extend %flex;
	// padding: 0 $gap * 2; // moved to break below.
	justify-content: space-between;
	text-align: left;

	// @include break(tablet) {
	// 	padding: 0 $gap * 2;
	// }

	@include break(large) {
		padding: 0;
	}

	&-outer {
		.section-header {
			margin-bottom: 4rem;

			&-title {
				text-align: initial;
			}
			&-summary {
				text-align: left;
				margin-left: 0;
			}
		}
	}

	.section-header {
		&-title {
			text-align: initial;
		}
	}

	&-item {
		@include flexbox(100%);
		margin: 0 0 $gap * 2;

		@include break(tablet) {
			@include flexbox(48%);

			&:last-child {
				@include flexbox(100%);
			}
		}

		@include break(medium) {
			@include flexbox(20%);
			margin: 0;

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ .statistic-item {
				@include flexbox(48%);
			}
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ .statistic-item {
				@include flexbox(30%);
			}
		}
		&:last-child {
			margin-bottom: $gap;

			@include break(small) {
				margin: 0 0 $gap * 2;
			}
		}
	}

	&-icon {
		font-size: 2rem;
	}

	&-value {
		display: inline-block;
		text-align: center;

		&-number {
			font-size: 3.75rem;
			line-height: 1.2em;
			font-weight: $semibold;
			font-family: $font-header;
			margin-bottom: 0.75rem;
			display: block;
			//...
			color: $pesp-navy;
		}
	}
	&-title {
		position: relative;
		font-size: 1.625rem;
		line-height: 1.4em;
		margin-bottom: 1.25rem;
		color: $pesp-navy;
		font-weight: $medium;
	}
}
