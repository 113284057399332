/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
	&-outer {
		// background-color: $primary;
		position: relative;

		&.section {
			padding: 0;
			@include break(medium) {
				padding: 6.25rem 0;
			}
		}

		&.dark,
		&.secondary,
		&.primary {
			h2,
			p {
				color: $white;
			}
		}

		&.full-width-image {
			padding: 0;

			@include break(tablet) {
				min-height: 80vh;
			}

			.cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
			}
		}

		&.site-width-image {
			padding: 0;

			@include break(tablet) {
				min-height: 60vh;
			}
			@include break(large) {
				margin: 0 auto;
				width: $page-width;
			}

			.cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
			}
		}

		.cta-image {
			//...
			display: none;
			@include break(tablet) {
				display: block;
			}
			@include break(medium) {
				clip-path: polygon(0 0, 100% 0, 100% 85%, 15% 75%);
			}
			+ .cta-inner {
				background-color: rgba($white, 0);
				border-radius: $corner;
				box-shadow: $shadow;
				color: $black;
				padding-left: 2rem;
				padding-right: 2rem;
				max-width: 90%;
				// margin: 4vh;

				// @include break(small) {
				// 	margin: 8vh;
				// }
				@include break(medium) {
					margin: 8vh;
					max-width: 33.125rem;
					padding-left: 0;
					max-width: 43.125rem;
				}
				@include break(large) {
					margin: 8vh;
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}
		.cta-inner {
			color: $white;
			padding: $gap * 1.5 0;
			text-align: center;
			position: relative;
			z-index: 1;

			@include break(small) {
				max-width: 100%;
			}

			@include break(tablet) {
				text-align: left;
				padding: 3rem 0;
			}
			@include break(medium) {
				padding: $gap * 3;
				width: $page-width / 2;
				margin: 0;
				padding: 0;
				max-width: 33.125rem;
			}
			@include break(large) {
				width: $page-width / 1.5;
				max-width: 43.125rem;
			}
			@include break(xxlarge) {
				width: $page-width / 1.2;
			}
			.cta-title {
				@include break(xxlarge) {
					font-size: 2.5rem;
				}
			}
		}
	}

	&-title {
		// ...
		@include break(medium) {
			margin-bottom: 1.5rem;
			font-size: 2.5rem;
			line-height: 3rem;
		}
	}

	&-summary {
		padding: $gap * 1.5 0 $gap * 0;
		@include break(medium) {
			padding: $gap * 1.5 0 $gap * 2;
		}
	}

	&-image {
		height: 100%;
		width: 100%;
		@include break(medium) {
			position: absolute;
			right: 0;
			top: 0;
			width: 40%;
		}
		@include break(large) {
			width: 30%;
		}

		img {
			@include object-fit(16/9, 100%);
		}
	}
	&-caption {
		background-color: $primary;
		color: $white;
		font-size: 0.8em;
		padding: $gap / 1.5 $gap * 1.5;

		@include break(tablet) {
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}
}
