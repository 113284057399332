//+++++++++++++++++++++++
//+++ HELPERS: LAYOUT +++
//+++++++++++++++++++++++

// Make a flexbox container
%flex {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
