/* +++++++++++++++++++
+++ LAYOUT: SEARCH +++
+++++++++++++++++++ */

.search {
	display: inline-block;
	margin: 0;
	position: relative;

	&:hover {
		.search-input {
			color: $pesp-primary;
			background: $white;

			&::placeholder {
				color: $pesp-primary;
			}
		}

		.search-icon {
			fill: $pesp-primary;
		}
	}

	&-input {
		font-size: 1em;
		font-weight: $semibold;
		margin-top: 0;
		padding: 0 $gap / 2 0 $gap * 2.2;
		border-width: 0.125rem;

		// ...
		@include break(large) {
			font-size: 1.2em;
			padding: 0 $gap / 2 0 $gap * 3;
		}
	}

	&-icon {
		@include centerer(false, true);
		fill: $white;
		cursor: pointer;
		height: 20px;
		display: inline-block;
		right: 0rem;
		pointer-events: none;
		width: 25px;
		transform: translate(3px, -50%);

		// ...
		@include break(large) {
			height: 25px;
			right: 0.8125rem;
		}

		&:hover {
			.search-button {
				background-color: $secondary;
			}
		}
	}
	&-button {
		// border-radius: 0 $corner $corner 0;
		// border-left: $line solid $grey80;
		height: 100%;
		overflow: hidden;
		padding: 0;
		position: absolute;
		left: 0;
		text-indent: -999rem;
		top: 0;
		width: 28px;
	}
}

.search-listing {
	.main-header-outer .main-header {
		max-width: 40.125rem;
		padding: 0 1rem;
		margin: auto;
	}
}
