/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
	@extend %flex;
	background: $pesp-primary;
	// flex-flow: column-reverse;
	position: relative;
	width: 100%;
	z-index: map-get($zindex, header);

	&-inner {
		@extend %flex;
		@include flexbox(100%);
		justify-content: space-between;
		margin: 0 auto;
		padding: $gap * 1;
		max-width: $page-container;
		// ...
		align-content: center;
		@include break(medium) {
			padding: $gap * 2 2rem;
		}
		@include break(large) {
			padding: $gap 2rem;
		}
	}
	// Search styling added
	.search {
		align-self: center;
		//
		display: none;
		@include break(large) {
			display: block;
		}
		&-input {
			height: 3.5rem;
			background: transparent;
			padding: 0.875rem 0.75rem 1rem 2.875rem;
			width: 10rem;

			&:focus {
				width: 18.75rem;
				border-color: $white;
			}

			@include break(medium) {
				width: 7.4375rem;
			}
			@include break(large) {
				width: 7.9375rem;
			}
			&::placeholder {
				color: white;
			}
		}
		&-button {
			height: 3.5rem;
			background: transparent;
		}
	}

	&-logo {
		margin-left: 0;
		// ...

		@include break(medium) {
			margin-right: 1rem;
		}
		@include break(large) {
			margin-right: 5rem;
		}
		&-image {
			width: 6rem;
			@include break(medium) {
				width: 6rem;
			}
			@include break(large) {
				width: 7.1875rem;
			}
		}
	}

	&-links {
		margin: 0;
		// ...
		align-self: center;

		@include break(medium) {
			display: block;
			padding-left: $gap / 2;
		}
	}
	&-nav {
		margin-left: 0;
		// ...
		align-self: center;
		color: $white;
		font-weight: bold;
		&-list {
			display: none;
			&:hover {
				color: $white;
			}

			@include break(medium) {
				display: block;
				margin: 0;
			}
		}
	}

	// Navigation items in the header and footer
	.nav-item {
		float: left;
		color: $white;
		//...
		position: relative;

		&:focus-within .dropdown,
		&:hover .dropdown {
			@include transition(opacity 0.2s 0.3s ease);
			opacity: 1;
			visibility: visible;

			.nav-item {
				float: none;
			}
		}
		&.hovered {
			.nav-item-link {
				box-shadow: inset 0 -2px 0 0 $white;
				color: $white;
			}
			.dropdown-item {
				.nav-item-link {
					box-shadow: none;
					color: black;
					&:hover {
						color: $pesp-primary;
					}
				}
			}
		}

		.button {
			margin-left: $gap;
		}

		&-link {
			@extend %text-hover;
			display: block;
			margin: 0 $gap;
			padding: $gap / 2 0;

			.social & {
				margin: 0;
				padding: $gap / 2 $gap;
			}

			&.current {
				box-shadow: inset 0 -2px 0 $secondary;
			}
			// ...
			@include break(medium) {
				font: $semibold 1rem/1.5 $font-header;
				margin: 0 0.75rem;
			}
			@include break(xlarge) {
				font: $semibold 1.125rem/1.2 $font-header;
				margin: 0 $gap;
			}
		}

		&-arrow {
			width: $gap;
		}
	}
	//     &-subnav {
	// 	    &-inner {
	// 		    @include flexbox(100%);
	// 		    background-color: $grey90;
	// 		    display: none;
	// 		    padding: $gap / 4 $gap;
	// 		    text-align: right;
	// 		    width: 100%;
	//
	// 		    @include break(medium) {
	// 			    display: block;
	// 		    }
	// 	    }
	//
	// 	    @include break(medium) {
	// 		    display: inline-block;
	//
	// 		    &-list {
	// 			    text-align: right;
	//
	// 			    .subnav-item {
	// 				    display: inline-block;
	//
	// 				    .nav-item-link {
	// 					    font-size: 0.9em;
	// 					    margin: 0 $gap / 2;
	// 				    }
	// 			    }
	// 		    }
	// 	    }
	//     }
}

// Responsive nav hamburger button
.menu-button {
	background-color: transparent;
	color: $white;
	cursor: pointer;
	font-size: 1.4em;
	font-weight: $bold;
	margin: $gap / 5 0 0 0;
	padding: 0 $gap * 1.5 0 0;
	position: relative;
	text-transform: uppercase;
	width: auto;

	&:hover {
		background-color: transparent;
		box-shadow: none;
		color: $white;
	}

	@include break(medium) {
		display: none;
	}
	.icon {
		@include centerer(false, true);
		height: 1em;
		right: 0;
		width: 1em;
	}
}

// Dropdown menu styling
.dropdown {
	@include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
	border-radius: 0;
	opacity: 0;
	position: absolute;
	visibility: hidden;
	margin: 0 1rem;
	padding-top: 30px;
	background: none;

	//...
	color: black;

	@include break(medium) {
		width: 320px !important;
	}

	//...
	&-item {
		background: $white;

		&:first-of-type {
			.nav-item-link {
				padding-top: 1.5rem;
			}
		}

		&:last-of-type {
			.nav-item-link {
				padding-bottom: 1.5rem;
			}
		}

		.nav-item-link {
			color: black;
			font-size: 1.125rem;
			line-height: 1.35rem;
			letter-spacing: 0.01em;
			font-weight: $normal;
			padding: 0.75rem 1.5rem;
			margin: 0;

			&:hover {
				color: $pesp-primary;
			}
		}
	}
}

// CMS tweaks
@if $wordpress {
	#wpadminbar {
		box-sizing: content-box;
		margin-top: 0;

		img {
			display: inline;
		}

		@media screen and (max-width: 600px) {
			position: fixed;
		}
	}
}

@if $drupal {
	.header-nav-outer {
		.nav-item-link {
			&.is-active {
				color: $grey15;
				font-weight: $bold;
			}
		}
	}
}
