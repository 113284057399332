.issue {
	&-outer {
		@include break(medium) {
			padding: 5rem 0;
		}

		.flexer {
			column-gap: 4.5rem;
		}

		.section-header {
			&-title {
				color: $pesp-primary;
				text-align: left;
				font-weight: 400;
				color: $pesp-navy;
				font-size: 1.625rem;
				font-weight: $medium;
			}
			&-summary {
				text-align: left;
				margin-left: 0;
			}
		}
		&.two-grid {
			padding-top: 0;
			.issue-inner {
				.item {
					.item-header {
						margin-top: 0;
						margin-bottom: 0.875rem;
					}
					.item-header-title {
						font-size: 1.375rem;
						letter-spacing: -0.01em;
						line-height: 1.35em;
					}
					.item-header-date {
						font-size: 0.875rem;
						line-height: 1.3em;
						letter-spacing: 0.01em;
						color: $grey50;
						margin: 0.4375rem 0;
					}
					.item-image {
						margin-bottom: 1rem;
						@include break(medium) {
							margin-bottom: 0;
						}
					}
				}
			}
			.flexer {
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 2rem;
				@include break(large) {
					grid-template-columns: 1fr 1fr;
					grid-gap: 4rem;
					row-gap: 6.25rem;
				}
			}
		}
		.container {
			padding: 0 1rem;
			margin: 1rem 0;

			@include break(medium) {
				padding: 0 $gap * 2;
				margin: 0 auto;
			}
		}
		.section-header {
			@include break(medium) {
				margin: 0 auto 3.25rem;
			}
		}
	}
	&-inner {
		margin-left: 0;
		.section-button-footer {
			max-width: 100%;
			margin-left: 0;
			padding-left: 0;

			@include break(medium) {
				margin-top: 4rem;
			}
		}
		&-title {
			border-bottom: 2px solid $pesp-navy;
			color: $pesp-navy;
			font-size: 1.625rem;
			font-weight: $medium;
			line-height: 1.4em;
			padding-bottom: 1.25rem;
			margin-bottom: 3.625rem;
		}
		.item {
			&-header {
				&-date {
					color: $grey60;
					align-self: flex-end;
				}
				&-tagflex {
					display: flex;
					.tags {
						margin: 0;
					}
				}
			}
			&-summary {
				margin-top: 1rem;
				p {
					max-width: 35rem;
					margin-left: 0;
				}
			}
			.tags {
				.metatag {
					&-link {
						background-color: transparent;
						border-radius: unset;
						color: $pesp-primary;
						display: inline-block;
						font: $bold 0.875rem/1.3em $font-body;
						margin: 0 $gap 0 0;

						&:hover {
							background: $pesp-navy;
							color: $white;
						}
					}
				}
			}
		}
	}
}

.home {
	.flexer {
		@include break(medium) {
			display: flex;
		}
	}
	.issue-inner {
		&:first-of-type {
			@include break(medium) {
				max-width: 40%;
			}
		}

		.item {
			transition: transform 0.2s ease-in-out;
			margin-bottom: 1.875rem;

			@include break(medium) {
				margin-bottom: 0;
			}

			&.hovered {
				box-shadow: unset;
				transform: scale(1.02);

				.item-header-title {
					color: $pesp-primary;
					text-decoration: none;
				}
				.metalabel {
					color: $pesp-primary;
				}
			}
			+ .item {
				border-top: unset;
			}
			&-header {
				&-date {
					@extend %font-meta;
					display: block;
					// ...
					color: $pesp-secondary;
					opacity: 0.7;
					margin: 0;
					font-size: 16px;
					line-height: 18.96px;
					letter-spacing: 0.02em;
				}
				&-title {
					color: $black;
					text-decoration: none;
					letter-spacing: -0.01em;
					margin-bottom: 1.5rem;
					font-size: 1.5rem;
					line-height: 1.4;

					@include break(tablet) {
						font-size: 1.875rem;
						line-height: 2.4375rem;
					}

					a {
						text-decoration: none;

						&:hover {
							color: $pesp-primary;
						}
					}
				}
			}
			&-info {
				.metalabel {
					text-transform: capitalize;
					color: $pesp-primary;
					font-weight: $semibold;
					letter-spacing: 0.01em;
					font-size: 0.875rem;
					line-height: 1.1375rem;
					margin-bottom: 1.25rem;
				}
			}
		}
		&:first-of-type {
			article {
				display: block;

				.item {
					&-image {
						flex: 100%;
						max-width: 100%;
					}
					&-info {
						flex: 100%;
						max-width: 100%;
						padding-right: unset;
						padding-top: 1.5625rem;
					}
				}
			}
		}
		&:not(:first-of-type) {
			article {
				.item {
					&-header {
						&-date {
							font-size: 0.875rem;
							line-height: 130%;
						}
						&-title {
							line-height: 1.85625rem;
							margin-bottom: 0.875rem;
							.item-link {
								font-size: 1.375rem;
							}
						}
					}

					&-info {
						padding-top: 1.5625rem;

						@include break(tablet) {
							padding-top: 0;
						}
					}
				}
			}
		}
	}
}
