/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
	@extend %flex;
	position: relative;
	width: 100%;
	column-gap: 4rem;

	> * {
		@extend %flex;
		@include flexbox(100%);
		margin: $gap * 1.5 0;

		&:first-child:nth-last-child(1),
		&:first-child:nth-last-child(1) ~ article {
			@include break(tablet) {
				flex: calc(50% - 2rem);
				max-width: calc(50% - 2rem);
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ article {
			@include break(tablet) {
				flex: calc(50% - 2rem);
				max-width: calc(50% - 2rem);
			}
		}
	}

	@include break(tablet) {
		> * {
			flex: 0 calc((100% - 4rem) / 2);
			max-width: calc((100% - 4rem) / 2);

			&:last-child {
				flex: 0 100%;
				max-width: 100%;
			}
		}
	}

	@include break(medium) {
		> * {
			flex: 0 calc((100% - 8rem) / 3);
			max-width: calc((100% - 8rem) / 3);

			&:last-child {
				flex: 0 calc((100% - 8rem) / 3);
				max-width: calc((100% - 8rem) / 3);
			}
		}
	}

	&-outer {
		&.cards-grid {
			.cards article {
				margin-bottom: 0;
			}
			.card-header-title {
				font-size: 1.625rem;
			}
			.card-wrap-inner {
				padding-top: 1.25rem;

				.metalabel {
					font-family: $font-body;
					font-size: 0.875rem;
					line-height: 1.3em;
					margin-bottom: 0.75rem;
				}
			}
		}

		.section-header {
			&-title {
				font-size: 1.5rem;
				line-height: 140%;
				text-align: left;
				color: $pesp-navy;
				font-weight: $medium;
				position: relative;

				&--icon {
					padding-left: 2.75rem;

					&::before {
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						left: 0rem;
						content: url(../src/svg/data_icon_navy.svg);
						position: absolute;
					}
				}

				@include break(medium) {
					font-size: 1.625rem;
				}
			}
			&-summary {
				text-align: left;
				margin-left: 0;
			}
			&.container {
				@include break(medium) {
					padding: 0 $gap * 2;
				}
			}
		}
		&.data-icon {
			.section-header-title {
				position: relative;
				padding-left: 2rem;
				font-weight: 400;
				&:before {
					left: 0rem;
					bottom: 6px;
					content: url("../src/svg/bluedata_icon.svg");
					position: absolute;
					transform: scale(1.4);
				}
			}
		}
	}
}

.card {
	@extend %card;
	// @extend %flex;
	@extend %transition;
	overflow: hidden;
	width: 100%;
	//...
	box-shadow: none;
	background-color: transparent;

	@include break(tablet) {
		margin: 0;
	}

	&.hovered {
		// box-shadow: 0 2vw 4vw rgba(0, 0, 0, 0.2);
		transform: scale(1.02);

		.card-header-title {
			color: $pesp-primary;
		}
	}

	&-image {
		display: block;
		margin: 0;
		position: relative;
		width: 100%;

		@include break(tablet) {
			height: 15rem;
		}

		img {
			@include object-fit(4/3, 100%);
		}
		.card-featured {
			background-color: $pesp-primary;
			color: $white;
			font-size: 0.9rem;
			font-weight: $bold;
			left: 0;
			padding: $gap / 2.5 $gap / 1.5;
			position: absolute;
			top: $gap * 1.5;
		}
	}

	&-wrap {
		align-self: stretch;
		height: 100%;
		margin: 0;

		&-inner {
			color: $black;
			padding: 1rem 0;

			@include break(medium) {
				padding: 2rem 0;
			}

			.metalabel {
				text-transform: capitalize;
				color: $pesp-primary;
				margin-bottom: 1rem;
			}
		}
	}

	&-header {
		margin-top: 0;

		+ .card-summary {
			margin-top: $gap / 2;
		}

		&-title {
			line-height: 130%;
			font-size: 1.375rem;

			@include break(medium) {
				font-size: 1.875rem;
			}
		}

		&-date {
			@extend %font-meta;
			display: block;
			margin-top: $gap * 1;
			font-size: 0.875rem;
			color: $grey50;
			line-height: 1.3em;
		}
	}
	&-button {
		&-outer {
			margin-top: $gap;
		}
	}
}

// cardfeed layout

.cardfeed {
	.cards {
		.card {
			box-shadow: unset;
			&:hover {
				box-shadow: unset;
			}
		}
		article {
			&:first-of-type {
				.card {
					@include flexbox(100%);
				}
				@include break(tablet) {
					> * {
						@include flexbox(50%);
					}
				}
				@include break(medium) {
					@include flexbox(45%);
				}
			}
			&:not(:first-of-type) {
				@include flexbox(100%);
				.card {
					border: 5px solid blue;
					display: flex;
				}
				border: 5px solid red;
			}
		}
	}
}

// Report layout

.reports {
	.card {
		&-image {
			background-color: $grey90;
			overflow: hidden;

			img {
				@extend %transition;
				bottom: -1em;
				border-radius: $corner $corner 0 0;
				box-shadow: $shadow;
				margin: $gap * 3 auto 0;
				position: relative;
				width: 60%;

				&:hover {
					bottom: -0.5em;
				}
			}
		}
		&-wrap {
			box-shadow: 0px -8px 22px 0px rgba($black, 0.3);
			z-index: 2;
		}
	}
}

// Listing layout

.listing {
	.card {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;

		&-wrap {
			border-top: solid 5px $primary;

			&-inner {
				padding: $gap 0 0 0;
			}
		}
	}
	&.secondary {
		.card {
			&-wrap {
				border-top: solid 5px $white;

				&-inner {
					color: $white;
				}
			}
		}
	}
}

// Two column layout
.two-col {
	.cards {
		> * {
			@include break(tablet) {
				@include flexbox(47%);
			}

			.card {
				&-image {
					height: 25rem;
				}
			}
		}
	}
}

// Three column layout
.three-col {
	.cards {
		> * {
			.card {
				&-image {
					height: 15rem;
				}
			}

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ article {
				@include flexbox(50%);

				.card {
					&-image {
						height: 15rem;
					}
				}
			}
		}
	}
}

// Four column layout
.four-col {
	.cards {
		> * {
			@include break(large) {
				@include flexbox(25%);
			}
			.card {
				margin: 0 $gap * 1.5;
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ article {
				@include flexbox(33.33%);
			}

			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ article {
				@include flexbox(50%);

				.card {
					margin: 0 $gap * 1.5;

					&-image {
						@include break(large) {
							height: 15rem;
						}
					}
				}
			}
		}
	}
}
//...
.single-post {
	.cards-outer {
		.section-header {
			text-align: left;
		}
	}
}

.home {
	.cards-outer {
		padding-top: 1.875rem;

		.container {
			padding: 0 1rem;

			@include break(medium) {
				padding: 0 2rem;
			}
		}

		.section-header {
			margin: 0;

			@include break(medium) {
				margin: 0 auto 2rem;
			}
		}
	}
}

.pbstyle {
	.card-header-title {
		font-size: 1.625rem;
		line-height: 1.3em;
	}
	.card-wrap-inner {
		padding: 1.25rem 0;

		.metalabel {
			margin-bottom: 0.75rem;
		}
	}
}
