/* ++++++++++++++++++++
+++ LAYOUT: ARTICLE +++
++++++++++++++++++++ */

// Article represents the part of a page that includes posted content including title and summary. Displaying as an inline-block allows the article to center nicely with or without a sidebar present.

.article {
	margin-top: 0;
	// padding: $gap * 2 $gap 0;
	position: relative;
	width: 100%;

	@include break(small) {
		margin-left: 0;
		padding: $gap * 2 $gap;
	}
	@include break(medium) {
		padding: $gap * 2 $gap;
	}

	&-header {
		&-summary {
			p {
				font: $light 1.33em/1.5 $font-header;
			}
		}
	}

	&-footer {
		border-top: $line solid $grey80;
		margin-top: $gap * 2;
		padding-top: $gap;
	}

	@include break(small) {
		padding: 0;
	}

	@include break(medium) {
		flex: 0 calc(100% - 48px - 320px);
		max-width: calc(100% - 48px - 320px);
		float: left;
	}

	@include break(large) {
		flex: 0 calc(100% - 93px - 400px);
		max-width: calc(100% - 93px - 400px);
		float: left;
	}

	//Inline components

	> * + * {
		margin: $gap * 2 0 0;
	}

	.accordion-outer,
	.pullquote {
		width: 100%;
	}
	.accordion-outer {
		background-color: $grey90;
	}
	.pullquote {
		&-image {
			margin-left: 0;
			@include break(medium) {
				margin-left: 2rem;
			}
			+ .pullquote-info {
				blockquote {
					font-size: 1.3em;
					padding: 0 1rem;
				}
			}
		}
		cite {
			font-size: 1em;
		}
	}
	.gallery {
		&-thumb {
			padding: 0;
		}
		.slick-slide {
			* {
				height: 100%;
			}
		}
		.slick-dotted {
			&.slick-slider {
				margin-bottom: 60px;
			}
		}
		.slick-prev {
			@include break(tablet) {
				left: -$gap;
			}
		}

		.slick-next {
			@include break(tablet) {
				right: -$gap;
			}
		}
	}
	.download {
		&-block {
			@include flexbox(100%);

			@include break(small) {
				@include flexbox(50%);
			}
		}
	}
	.cta {
		background-color: $pesp-light;
		border-radius: $corner;
		//...
		padding: 0.5rem;
		@include break(medium) {
			padding: $gap * 2;
		}
		position: relative;
		&.dark {
			h2,
			p {
				color: $white;
			}
		}

		&-inner {
			text-align: left;
			//...
			margin-left: 0;

			@include break(medium) {
				padding-right: 1rem;
				width: 50%;
			}
		}
		//...
		&-image {
			width: 100%;
			@include break(medium) {
				width: 50%;
			}
		}
	}
	.media-block {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		min-height: auto;

		&-element {
			@include flexbox(100%);
			border-radius: 0;

			img {
				position: relative;
			}
			.video-outer {
				display: block;
				padding-bottom: 56.25%; /* 16:9 */
				height: 0;
				position: relative;

				iframe {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
		}
		&-info {
			display: none;
		}
		&-caption {
			background-color: transparent;
			display: inline-block;
			font-size: 0.9em !important;
			margin-top: 0 !important;
			overflow-wrap: break-word;
			padding: $gap / 2 $gap $gap / 2 0;
			word-wrap: break-word;
			margin: 0;
			//...
			color: $grey60;
		}
	}
	.profile {
		margin: $gap * 2 (-$gap * 1.5);

		@include break(medium) {
			margin: $gap * 2 (-$gap * 2);
		}
		&-element {
			margin: $gap * 1.5 0;

			@include break(large) {
				@include flexbox(33.33%);
			}
			&:nth-child(n + 4),
			&:nth-child(n + 5) {
				margin: $gap * 1.5 0;
			}
		}
	}
}
