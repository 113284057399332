/* ++++++++++++++++
+++ LAYOUT: CMS +++
++++++++++++++++ */

 // Wordpress
 
 body.logged-in {
	 .header {
		/* position: relative; */
	 }
	 .main {
		 margin-top: 0;
	 }
 }
 
@if $wordpress {
	body.admin-bar {
		.header, .lg-outer {
			@include break(medium) {
				top: 33px;
			}
		}
	}
	blockquote {
		&:before, &:after {
			display: none !important;
		}
		p {
			quotes: "“" "”" "‘" "’";
			
			&:before {
			    content: open-quote;
			    display: inline-block;
			}
			&:after {
			    content: close-quote;
			    display: inline-block;
			}	
		}
	}
}

@if $drupal {
	.local-task-list {
		text-align: center;
		&-base {
			background-color: $grey30;
			bottom: 0;
			margin-top: 0;
			padding: $gap 0;
			position: fixed;
			width: 100%;
			
			* {
				margin-top: 0;
			}
		}
		&-block {
			@extend %font-header;
			@extend %transition;
			background: $primary;
			border-radius: $corner;
			color: $white;
			display: block;
			line-height: 35px;
			list-style: none;
			text-align: center;
			width: 100%;
	
		    @include break(small) {
				display: inline-block;
				margin-left: $gap;
				width: auto;
		    }
	
			&:hover {
				background: lighten($primary, 12%);
			}
	
			&:first-child {
				margin-left: 0;
			}
	
			a {
				display: block;
	
				@include break(small) {
					padding: 0 $gap / 1.5;
				}
			}
		}
	}
	
	@include break(medium) {
	    #toolbar-administration .hover-intent .toolbar-menu .toolbar-menu {
	        margin-left: 200px;
	        margin-top: -39px;
	    }
	}
	
	#toolbar-item-administration-tray {
		margin: 0;
	}

	@media screen and (min-width: 960px) {
		.toolbar-horizontal .header {
			top: 54px;
		}
	}
	#toolbar-item-administration-tray nav .menu-item {
		margin: unset;
	}
	.local-task-list {
		position: relative;
		z-index: 999;
	}
}
