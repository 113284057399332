.btnlist {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		column-gap: 2rem;
		max-width: 65rem;
		margin: 0 -1rem;

		@include break(tablet) {
			margin: 0 auto;
		}
	}
	&-inner {
		width: 100%;
		margin: 0.5rem 1rem;
		@include break(tablet) {
			width: calc((100% - 2rem) / 2);
			margin: 0 0 2rem;
		}
		@include break(medium) {
			width: calc((100% - 4rem) / 3);
		}
		// @include break(medium) {
		// 	width: 30%;
		// }
		.button {
			padding: 1rem;
			width: 100%;
			text-align: initial;
			position: relative;
			background: $pesp-primary;
			&:hover {
				background: $pesp-navy;
			}

			@include break(tablet) {
				padding: 2rem;
			}

			&:after {
				position: absolute;
				content: "";
				width: 0;
				height: 0;
				top: -25px;
				right: -2px;
				border-bottom: 200px solid $white;
				border-left: 25px solid transparent;
			}
		}
	}
	&-outer {
		h2 {
			font-size: 1.5rem;
			line-height: 130%;

			@include break(tablet) {
				font-size: 2.125rem;
				line-height: 130%;
			}
		}

		.section-header {
			margin-bottom: 0;
		}

		.section-header-summary {
			font-size: 1.125rem;
			line-height: 160%;
			max-width: 43.4375rem;
		}

		&.dark {
			.button {
				&:after {
					border-bottom: 100px solid $pesp-secondary;
				}
			}
		}
		&.light {
			.button {
				&:after {
					border-bottom: 100px solid $pesp-light;
				}
			}
		}
	}
}
