/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

.pagination-outer {
	padding: 0 0 6.25rem 0;
}

%pagination {
	@extend %flex;
	@extend %font-meta;
	text-align: center;
	justify-content: space-between;

	a {
		@extend %transition;
	}

	&-block {
		margin: 0;
		width: 48%;
	}

	&-number {
		display: none;
	}

	&-element {
		display: block;
		padding: $gap / 4 $gap / 2;
		overflow: hidden;
		height: 3.5rem;
		width: 3.5rem;
		line-height: 3.4rem;
		padding: 0;
		font-weight: $medium;
		font-family: $font-header;
		border: 2px solid $white;

		&:hover {
			border-color: $grey90;
			background: transparent;
		}
	}

	&-arrow {
		@extend .button;
		display: block;
	}

	&-current {
		border-color: $secondary;
		color: $secondary;
	}

	@include break(tablet) {
		justify-content: center;

		&-number {
			display: block;
		}

		&-block {
			margin: 0 $gap / 4;
			width: auto;
		}

		&-element {
			display: block;
		}
	}
}

// @if $wordpress { // need to add back in
.pagination {
	@extend %pagination;

	&_block {
		@extend %pagination-block;

		&.next {
			float: right;
		}

		&.numeral {
			@extend %pagination-number;
		}

		&.view_all {
			display: none;
		}
	}

	&_element {
		@extend %pagination-element;

		&.prev,
		&.next {
			border-color: $pesp-yellow;

			&:hover {
				border-color: $pesp-navy;
			}
		}

		&.prev {
			@extend %pagination-arrow;
			font-size: 0.8em;

			@include break(medium) {
				font-size: 1em;
			}
		}

		&.next {
			@extend %pagination-arrow;
			font-size: 0.8em;

			@include break(medium) {
				font-size: 1em;
			}
		}

		&.current {
			@extend %pagination-current;
		}
	}
}
// }

@if $drupal {
	.pager {
		padding: $gap 0;

		&__items {
			@extend %pagination;
		}

		&-first {
			float: right;
		}

		&__item {
			@extend %pagination-block;

			a {
				@extend %pagination-element;
				padding: $gap / 2 $gap;
			}

			&.is-active {
				@extend %pagination-element;
				@extend %pagination-current;

				a {
					@include break(small) {
						padding: 0;
					}
				}
			}

			&--first a,
			&--previous a,
			&--next a,
			&--last a {
				@extend %pagination-arrow;
				@extend %pagination-block;
			}
		}

		&-ellipsis {
			@extend %pagination-element;
		}
	}
}
