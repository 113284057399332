/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

body.home {
	.hero-title {
		@extend %h1;
		padding: 0;
		font-size: 1.7rem;
		line-height: 2.3rem;
		text-align: left;

		@include break(medium) {
			line-height: 3.2rem;
			font-size: 2.5rem;
			width: 100%;
		}
	}
	mark {
		background-image: linear-gradient(
			to right,
			$pesp-secondary 50%,
			$pesp-secondary 50%
		);
		background-size: 200% 2.5625rem;
		background-repeat: no-repeat;
		background-position: 100% 0;
		transition: background-position 0.3s;
	}
}

body.pbstyle {
	.hero-outer {
		&.solid {
			.hero {
				&.solid {
					.hero-inner {
						color: black;
					}
				}
				&-image {
					@include break(medium) {
						@include flexbox(75%);
						&:before {
							content: "";
							width: 0;
							height: 0;
							border-top: 100rem solid white;
							border-right: 20rem solid transparent;
							position: absolute;
							left: 0;
							bottom: -20rem;
						}
					}
				}
			}
		}
		&.primary {
			.hero {
				&-image {
					@include break(medium) {
						@include flexbox(75%);
						&:before {
							content: "";
							width: 0;
							height: 0;
							border-top: 100rem solid $pesp-primary;
							border-right: 20rem solid transparent;
							position: absolute;
							left: 0;
							bottom: -20rem;
						}
					}
				}

				&-info {
					padding: 20px 0;

					@include break(medium) {
						padding: 0;
						width: 60%;
						flex: 0 60%;
					}
				}
			}
		}
		&.dark {
			.hero {
				&-image {
					@include break(medium) {
						@include flexbox(75%);
						&:before {
							content: "";
							width: 0;
							height: 0;
							border-top: 100rem solid $pesp-navy;
							border-right: 20rem solid transparent;
							position: absolute;
							left: 0;
							bottom: -20rem;
						}
					}
				}
			}
		}
		&.secondary {
			.hero {
				&-image {
					@include break(medium) {
						@include flexbox(75%);
						&:before {
							content: "";
							width: 0;
							height: 0;
							border-top: 100rem solid $pesp-secondary;
							border-right: 20rem solid transparent;
							position: absolute;
							left: 0;
							bottom: -20rem;
						}
					}
				}
			}
		}
		&.light {
			.hero {
				&-image {
					@include break(medium) {
						@include flexbox(75%);
						&:before {
							content: "";
							width: 0;
							height: 0;
							border-top: 100rem solid $pesp-light;
							border-right: 20rem solid transparent;
							position: absolute;
							left: 0;
							bottom: -20rem;
						}
					}
				}
			}
		}
	}
}
.hero {
	@extend %flex;
	position: relative;
	width: 100%;
	display: block;

	@include break(medium) {
		flex-flow: row-reverse nowrap;
		display: flex;
	}

	&-outer {
		padding: 0;
		position: relative;
		display: block;
		height: auto;

		@include break(medium) {
			height: 33.125rem;
			display: flex;
		}

		&.dark,
		&.primary,
		&.secondary {
			height: auto;
			display: block;

			.hero {
				@include break(medium) {
					padding: 100px 0;
				}
			}

			.hero-inner {
				.metalabel {
					color: $pesp-yellow;
				}
				.hero {
					&-title,
					&-summary {
						color: $white;
					}
				}
			}
		}
		&.light {
			.hero-inner {
				.metalabel {
					color: $pesp-primary;
					margin-bottom: 2rem;
					display: block;
				}
				.hero {
					&-title,
					&-summary {
						color: $black;
					}
				}
			}
		}
		&.primary {
			&:before {
				content: "";
				border-top: 100rem solid $pesp-primary;
			}
		}

		&.pbuilder {
			.hero {
				&-image {
					@include flexbox(100%);
					overflow: hidden;

					// @include break(medium) {
					// 	@include flexbox(75%);
					// 	&:before {
					// 		content: '';
					// 		width: 0;
					// 		height: 0;
					// 		// border-top: 100rem solid $pesp-primary;
					// 		border-right: 20rem solid transparent;
					// 		position: absolute;
					// 		left: 0;
					// 		bottom: -20rem;
					// 	}
					// }
					@include break(large) {
						@include flexbox(60%);
					}
					position: relative;
				}
				&-summary {
					padding-top: 1rem;

					@include break(large) {
						padding-top: 2rem;
					}
				}
			}
		}

		&.full {
			.hero-image {
				@include flexbox(100%);
				height: 100%;
				width: 100%;

				@include break(medium) {
					left: 0;
					position: absolute;
					top: 0;
				}

				+ .hero-info {
					@include flexbox(100%);
					background-color: rgba($white, 0.7);
					box-shadow: $shadow;
					position: relative;
					text-align: center;
					z-index: 2;

					@include break(medium) {
						@include flexbox(80%);
						border-radius: $corner;
						margin: 10em auto;
					}

					@include break(medium) {
						@include flexbox(50%);
					}

					.hero-inner {
						color: $black;
						margin: 0;
						padding: 3rem;
						width: auto;
					}
				}
			}
			.hero-summary {
				p {
					padding: 0;
				}
			}
		}
		&.solid {
			.container {
				display: flex;
			}

			.hero-image {
				width: 100%;
				height: 12.5rem;

				@include break(medium) {
					position: absolute;
					bottom: 0;
					right: 0;
					width: 40%;
					height: 100%;
				}

				+ .hero-info {
					background-color: transparent;
					box-shadow: none;
					max-width: 45.625rem;

					@include break(medium) {
						@include flexbox(80em);
						margin: 0em auto;
					}
					@include break(xxlarge) {
						@include flexbox(80em);
						margin: 8em auto;
					}

					.hero-inner {
						// box-shadow: $shadow;
						background-color: transparent;
						color: $white;
						text-align: left;
						padding: 1.25rem 0;

						@include break(medium) {
							@include flexbox(100%);
							// margin-left: $gap * 2;
							margin: auto;
							text-align: left;
						}
						@include break(large) {
							@include flexbox(90%);
							// margin-left: $gap * 2;
							margin: auto;
							text-align: left;
						}
					}
				}
			}
		}
		&.listing {
			text-align: center;

			.hero-info {
				background-color: $secondary;

				.hero-inner {
					margin: 0 auto;

					@include break(medium) {
						width: $page-width / 1.5;
					}
					.hero-title,
					.hero-summary {
						@include break(medium) {
							width: 100%;
						}
					}
				}
			}
		}
	}
	&-info,
	&-image {
		margin: 0;
	}
	&-info {
		@extend %flex;
		@include flexbox(100%);
		background-color: transparent;
		align-items: center;
		max-width: 45.625rem;

		.hero-summary {
			padding-top: $gap;
		}
		a {
			margin-top: 2rem;
			text-decoration: underline;
		}
		.button {
			text-decoration: none;
			text-align: left;
			margin-top: 1.25rem;

			@include break(medium) {
				margin-top: 2rem;
			}
		}

		.metalabel {
			a {
				text-decoration: none;
				color: $pesp-primary;
			}
		}
	}
	&-inner {
		color: $black;
		margin: 0 auto;
		padding: 1.125rem 0;

		@include break(medium) {
			padding: 0;
			margin: 0;
		}

		.hero-title,
		.hero-summary {
			@include break(medium) {
				margin-left: 0;
			}
		}
	}
	&-image {
		@include flexbox(100%);
		overflow: hidden;

		@include break(medium) {
			@include flexbox(75%);
		}
		@include break(large) {
			@include flexbox(50%);
		}
		+ .hero-info {
			@include break(medium) {
				@include flexbox(50%);
			}
			.hero-inner {
				margin: 0 0 0 auto;

				@include break(medium) {
					width: 40em;
				}
				.hero-title,
				.hero-summary {
					@include break(medium) {
						margin-left: 0;
						width: 100%;
						max-width: 45.625rem;
					}
				}
			}
		}
		img {
			@include object-fit(16/9, 100%);
		}
	}
}

.home {
	.hero {
		&-image {
			img {
				@include break(medium) {
					clip-path: polygon(12% 20%, 100% 8%, 100% 100%, 0% 100%);
				}
				@include break(xxlarge) {
					clip-path: polygon(12% 30%, 100% 15%, 100% 100%, 0% 100%);
				}
			}
		}
	}
}
.single-post {
	.hero-outer {
		.hero {
			&-image {
				@include break(medium) {
					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 0;
						height: 0;
						border-top: 80rem solid $white;
						border-right: 100px solid transparent;
					}
				}
				+ .hero-info {
					@include break(medium) {
						@include flexbox(30em);
						margin: 0 auto;
					}
					@include break(large) {
						@include flexbox(45em);
						margin: 1em auto;
					}
					@include break(xxlarge) {
						@include flexbox(55em);
						margin: 5em auto;
					}
				}
			}
		}
		&.light {
			.hero {
				&-image {
					@include break(medium) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 0;
							height: 0;
							border-top: 80rem solid $pesp-light;
							border-right: 100px solid transparent;
						}
					}
				}
			}
		}
		&.dark {
			.hero {
				&-image {
					position: relative;
					@include break(medium) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 0;
							height: 0;
							border-top: 80rem solid $pesp-navy;
							border-right: 100px solid transparent;
						}
					}
				}
			}
		}
		&.primary {
			.hero {
				&-image {
					@include break(medium) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 0;
							height: 0;
							border-top: 80rem solid $pesp-primary;
							border-right: 100px solid transparent;
						}
					}
				}
			}
		}
		&.secondary {
			.hero {
				&-image {
					position: relative;
					@include break(medium) {
						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 0;
							height: 0;
							border-top: 80rem solid $pesp-secondary;
							border-right: 100px solid transparent;
						}
					}
				}
			}
		}
	}
}
