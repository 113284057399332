/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
	align-items: flex-end;
	justify-content: flex-start;
	max-width: $page-width;
	padding: $gap * 1.5 $gap;
	margin: 0;
	width: 90%;

	@include break(small) {
		padding: $gap $gap 6.25rem;
		width: 90%;
	}

	@include break(tablet) {
		width: 100%;
		margin: auto;
	}

	.search-filter {
		&-icon {
			position: relative;
			width: 10rem;
			margin: 0;
			&:before {
				position: absolute;
				top: 55%;
				transform: translate(0%, -50%);
				left: 1rem;
				content: url("../src/svg/search_icon.svg");
				z-index: 1;
			}
		}
		&-block {
			padding: unset;
			&:last-child {
			}

			.filter-submit {
				position: relative;
				width: 100%;
			}
		}
	}
	&-search-outer {
		position: relative;
		.filter {
			@include break(tablet) {
				padding: 1rem 1rem 1.5rem;
			}
			@include break(tablet) {
				padding: $gap 1rem 6.25rem;
				max-width: 40.125rem;
				margin: auto;
			}

			.filter-block {
				&:first-child {
					width: calc(100% - 136px);
					flex: 0 0 calc(100% - 136px);
				}

				&:last-child {
					width: 136px;
					flex: 0 0 136px;
				}
			}
		}
	}

	&-field {
		color: $black;
		height: 3.375rem;
		border-color: $white;
		font-size: 1.125rem;
		font-family: $font-header;
		line-height: 1.2em;
		font-weight: $medium;
	}

	&-outer {
		background-color: $pesp-primary;

		&.small {
			.filter {
				max-width: $half-column;
			}
		}
	}

	&-button {
		text-align: center;
	}

	&-show-button {
		@extend %transition;
		background-color: transparent;
		background-image: url("../images/select-arrow.svg");
		background-position: right center;
		background-repeat: no-repeat;
		background-size: 1rem;
		cursor: pointer;
		display: block;
		padding: $gap $gap * 2 $gap 0;
		margin: 0 16px;
		text-decoration: none;
		font-size: 1.125rem;
		font-family: $font-header;

		&:hover {
			text-decoration: none;
		}

		@include break(small) {
			margin: 0 2rem;
		}

		@include break(tablet) {
			display: none;
		}

		span {
			color: $white;
		}
	}
	&-inner {
		display: none;

		@include break(tablet) {
			display: block !important;
		}
	}

	&-block {
		flex: 0 100%;
		padding: 0 0 $gap * 1.5;
		text-align: left;

		@include break(small) {
			padding: 0 $gap * 2.5 $gap * 1.5;
		}

		@include break(tablet) {
			flex: 1 12em;
			padding: 0 $gap;
		}

		label {
			color: $white;
			font-weight: $bold;
		}

		select {
			background-position: 94% center;
		}
	}

	&-submit {
		background-color: $pesp-yellow;
		height: 3.375rem;
		font-weight: $medium;

		@include break(small) {
			margin-top: 0;
		}
		@include break(medium) {
			width: 60%;
			width: 8.875rem;
		}

		&:hover {
			background-color: $white;
			color: $black;
		}
	}
}

//...
.search-listing {
	.main-header {
		padding: 0 2rem 0 5.5rem;
		@include break(medium) {
			padding: 0 2rem 0;
		}
		&-title {
			@include break(tablet) {
				text-align: left;
			}
		}
	}
}
