/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	background-color: transparent;
	// border-radius: $corner;
	box-shadow: 0px 0px 30px rgba($black, 0.2);
	min-height: 420px; // increased by 200px to account for section padding and margin unsetting below.

	@include break(xxlarge) {
		min-height: 620px;
	}

	&-outer {
		// section added to pull image to edges.
		&.section {
			padding: unset;
			.container {
				padding: unset;
				margin: unset;
				max-width: unset;
			}
		}
		&.no-image {
			.media-block {
				display: block;
				&-info {
					flex: unset;
					max-width: unset;
					padding: 1rem;
					@include break(tablet) {
						padding: 6.25rem 8rem;
					}
				}
				&-content {
					text-align: center;
				}
			}
		}
		&.dark {
			h2,
			p {
				color: $white;
			}
			.button {
				border: 3px solid $pesp-yellow;
				&:hover {
					color: white;
					border: 3px solid $pesp-yellow;
					background: transparent;
				}
			}
		}
		&.right {
			.media-block {
				@include break(medium) {
					flex-flow: row-reverse nowrap;
				}
				&-element {
					@include break(medium) {
						// border-radius: 0 $corner $corner 0;
					}
				}
			}
		}
		&.secondary {
			.media-block {
				background: $white;

				&-info {
					color: $black;
				}
			}
		}
		&.video {
			.media-block {
				min-height: auto;

				&-element {
					@include flexbox(100%);
					overflow: hidden;

					@include break(medium) {
						@include flexbox(50%);
					}

					.video-outer {
						display: block;
						padding-bottom: 56.25%; /* 16:9 */
						height: 0;
						position: relative;

						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}
					}
				}
				&-info {
					@include break(medium) {
						@include flexbox(50%);
					}
				}
			}
		}
	}

	&-element,
	&-info {
		margin: 0;
	}
	&-info {
		@extend %flex;
		align-items: center;
		color: $black;
		padding: $gap * 1.5;

		@include break(small) {
			padding: $gap * 3;
		}

		@include break(medium) {
			@include flexbox(50%);
			padding: 6.25rem 3rem;
		}
		@include break(xlarge) {
			padding: $gap * 6.25 $gap * 8 $gap * 6.25 $gap * 2;
			max-width: 50rem;
		}

		.button {
			margin-top: 1rem;
			font-size: 1.125rem;
			line-height: 120%;
			padding: 1rem 1.25rem;
			font-weight: $medium;
		}
	}
	&-content {
		text-align: center;
		@include break(medium) {
			text-align: left;
		}
		p {
			padding-bottom: $gap;
			font-size: 1.125rem;
			line-height: 2.1125rem;

			a {
				@extend %text-hover;
				text-decoration: underline;
			}
		}
	}
	&-element {
		@include flexbox(100%);
		// border-radius: $corner $corner 0 0;
		overflow: hidden;
		position: relative;

		@include break(medium) {
			@include flexbox(50%);
			// border-radius: $corner 0 0 $corner;
		}

		&-play {
			@extend .button;
			@include centerer(true, true);
			background-color: $red;
			display: inline-block;
			width: auto;
			z-index: 2;
		}

		img {
			@include object-fit(16/9, 100%);

			@include break(medium) {
				border-radius: 0;
				left: 0;
				position: absolute;
				top: 0;
			}

			.no-objectfit & {
				width: auto;
			}
		}
	}
	&-title {
		font-size: 2rem;
		line-height: 135%;
		margin-bottom: 1.5rem;
		padding-bottom: 0;
		font-weight: $medium;
	}
}
