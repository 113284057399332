/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
    @extend %section;

    &.primary { background: $pesp-primary; }
    &.secondary {  background: $pesp-secondary; }
    &.dark {  background: $pesp-navy; }
    // &.grey {  background: $grey80; }
	&.light {  background: $pesp-light; }

    &.primary, &.secondary, &.dark {
        .section-header, .section-footer, .main-header {
            color: $white;
        }

        &.wysiwyg, &.statistic-outer, &.download-outer, &.pullquote-outer, &.profile-outer {
            color: $white;
        }
        &.listing {
            .card-wrap {
                border-top: solid 5px $white;

                &-inner {
                   color: $white;
                }
            }
        }
    }
	&.light {
		.section-header, .section-footer, .main-header {
			color: $black;

			&.wysiwyg, &.statistic-outer, &.download-outer, &.pullquote-outer, &.profile-outer {
				color: $black;
			}
			&.listing {
				.card-wrap {
					border-top: solid 5px $pesp-navy;

					&-inner {
				   	color: $black;
					}
				}
			}
		}
	}

    &.subnav {
	    background: $white;
    }

    &-header {
	    margin: 0 auto 0;
        text-align: center;

        @include break(small) {
            margin: 0 auto $gap * 2;
        }

        &-title {
	        + .section-header-summary {
		        padding-top: $gap / 1.5;
	        }
        }

        &-summary {
	        font-size: 1em;
            padding-bottom: $gap;

            @include break(small) {
	            font-size: 1.125em;
            }

            @include break(medium) {
            	padding: 0 0 $gap;
            	width: $half-column;
            }
        }
    }

    &-footer {
        @extend %container;
        margin-top: $gap;
        text-align: center;

        @include break(small) {
	        margin-top: $gap * 2;
        }
    }

    &.listing {
	    .card-image {
		    display: none;
	    }
    }
}

.container {
    @extend %container;

    &.centered { text-align: center; }
    &.small { max-width: $two-thirds-column; }

    &.white,
    &.grey,
    &.dark,
    &.primary,
    &.secondary {
        padding: $gap;

        @include break(small) {
            padding: $gap * 2;
        }

        @include break(large) {
            border-radius: $corner;
            max-width: $page-width - $gap * 4;
        }
    }

    &.dark,
    &.primary,
    &.secondary {
        color: $white;
    }


    &.white { background: $white; }
    &.grey { background: $grey90; }
    &.dark { background: $grey15; }
    &.primary { background: $primary; }
    &.secondary { background: $secondary; }
}
