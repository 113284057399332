.embed {
	&-outer {
		.section-header {
			text-align: left;

			&-title {
				@extend %h4;
				margin-bottom: 1.5rem;
			}

			&-summary {
				margin-left: 0;
				font-size: 1.125rem;
				line-height: 1.6em;
			}
		}
		iframe {
			width: 100%;
			min-height: 35rem;
		}
	}
}
