/* ++++++++++++++++++++++
+++ LAYOUT: PULLQUOTE +++
++++++++++++++++++++++ */

.pullquote {
	@extend %flex;
	font-family: $font-quote;
	justify-content: left;
	margin: 0 auto;
	padding: $gap * 1.5;
	text-align: center;
	position: relative;
	// ..
	background-color: $pesp-light;

	@include break(small) {
		align-items: center;
		text-align: left;
	}

	@include break(medium) {
		padding: $gap * 3;
		&::after {
			content: "";
			width: 0;
			height: 0;
			border-bottom: 30rem solid $white;
			border-left: 60px solid transparent;
			position: absolute;
			bottom: -30px;
			right: -4px;
		}
	}

	@include break(xlarge) {
		margin: 0 auto;
		width: 64rem;
	}

	// created in pullquote.scss to remove padding to allow pseudo :after above.
	&-outer {
		.container {
			padding: 0;
		}
	}

	&-image {
		@include flexbox(100%);
		//...
		margin: 0;
		align-self: flex-start;
		@include break(small) {
			@include flexbox(4.5rem);
		}

		img {
			border-radius: 100%;
			width: 4.5rem;
			height: 4.5rem;
			margin: 0 auto;

			@include break(medium) {
				margin: 0;
			}
		}

		+ .pullquote-info {
			@include flexbox(100%);
			padding: $gap * 1;

			@include break(small) {
				@include flexbox(80%);
				padding: 0 0 0 $gap * 1;
			}

			@include break(medium) {
				padding: 0 $gap * 2;
			}
		}
	}
	&-info {
		margin: 0;
		@include flexbox(100%);
		padding: 0;

		@include break(small) {
			padding: 0;
		}

		@include break(medium) {
			padding: 0 $gap * 5 0 0;
		}
	}

	blockquote {
		font-weight: $bold;
		quotes: "“" "”" "‘" "’";
		font-family: $font-header;
		font-size: 1.5rem;
		line-height: 1.35em;
		font-weight: $medium;
		margin-bottom: 1rem;
		//...
		color: $pesp-navy;

		// &:before {
		//     content: open-quote;
		//     display: inline-block;
		// }
		// &:after {
		//     content: close-quote;
		//     display: inline-block;
		// }

		@include break(medium) {
			font-size: 2rem;
		}
	}
	cite {
		font-style: normal;
		color: $pesp-navy;
		font-size: 1.125rem;
		line-height: 1.6em;
		margin: 0;
	}
	&-share {
		display: block;
		position: relative;

		@include break(medium) {
			display: inline-block;
			padding-left: $gap * 5;
			top: -4px;
		}

		&-title {
			@extend %font-content;
			color: $grey30;
			font-size: 0.7em;
			font-weight: $normal;
			padding: 0 0 0 $gap * 4;
			position: relative;
			text-transform: uppercase;

			&:before {
				background-color: $grey30;
				content: "";
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 6px;
				width: 36px;
			}
		}
		&-element {
			@extend %transition;
			background-color: $twitter;
			border-radius: 100%;
			color: $white;
			display: inline-block;
			height: 30px;
			margin: 0 0 0 $gap / 2;
			text-align: center;
			width: 30px;

			.icon {
				margin-top: 3px;
			}
		}
	}
}
//..
.no-image {
	.pullquote-outer {
		.pullquote {
			&-info {
				padding: 1rem;
				@include break(tablet) {
					padding: 1rem 2rem;
				}
				@include break(medium) {
					padding: 0 $gap * 5;
				}
			}
		}
		blockquote {
			padding: 1.5rem 0.5rem;
		}
	}
}
