//++++++++++++++++++++++
//+++ HELPERS: FORMS +++
//++++++++++++++++++++++

%form-input {
    @extend %transition;
    color: $white;
    // border-radius: $corner;
    border: $line solid $white;
    font: $normal 1em/1.5 $font-body;
    height: 2.5em;
    padding: 0 $gap;
    width: 100%;

    &:disabled {
        background: $white;
    }
}

form {
    font: 1rem $font-body;

    p { font: 1em $font-body; }
}

input,
textarea,
select {
    @extend %form-input;
}

[type="text"],
[type="number"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="time"],
[type="search"],
[type="tel"],
[type="url"],
[type="email"],
[list],
textarea,
select {
    background: $white;
    outline: none;

    &:focus {
        border-color: $primary;
    }

	&::-webkit-input-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&::-moz-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&:-ms-input-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $grey50;
	  opacity: 1;
	}
}

textarea {
    border: 1px solid $black !important;
    color: $black !important;
    resize: vertical;
    padding: $gap / 2 $gap;
    height: auto;
    min-height: $gap * 8;
}

[type="reset"],
[type="submit"] {
    @extend .button;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
}
button {
    border: 0;
    cursor: pointer;

    &:hover {
        color: $primary;
        text-decoration: underline;
    }
}

[type="color"],
[type="file"] {
    border: none;
    line-height: 1;
    padding: 0;
}

[type="file"] {
    height: auto;
}
select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url('../images/select-arrow.svg');
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: 25px;
	border: solid 1px #A1AEB7;

	@include break(small) {
		background-image: url('../images/select-arrow.svg');
		background-position: 98% center;
		background-repeat: no-repeat;
	}
	&::-ms-expand {
	    display: none;
	}
}

label {
    font: $normal 1em/1.5 $font-body;
    display: block;

    & + input,
    & + textarea,
    & + select { margin-top: $gap / 4; }
}

legend {
    border-bottom: $line solid $grey90;
    padding-bottom: $gap / 2;
    width: 100%;
}

[type="checkbox"],
[type="radio"] {
    float: left;
    height: $gap * 1.5;
    margin-right: $gap / 2;
    width: auto;

    & + label {
        display: inline;
        font-style: normal;
    }
}

// Chosen styling

.chosen-container {
    margin-top: $gap / 4;
    width: 100% !important;

    .chosen-single {
        background: $white !important;
        height: 2.5rem !important;
        padding-left: $gap;

        span {
            color: $grey20 !important;
            font-size: 1rem;
            line-height: 39px;
        }
        div {
            b {
                background-image: url('../images/select-arrow.svg') !important;
                background-position: center !important;
                background-size: 25px !important;
                margin-left: -20px;
                width: 25px !important;
            }
        }
    }
}

@if $wordpress {
    .gform_body {
		.ginput_container {
			* {
				margin-top: 0;
			}
			.gfield_checkbox {
				input[type=checkbox] {
					margin-top: 0 !important;
				}
				.gfield-choice-input {
					label {
						margin-top: 0rem !important;
					}
				}
			}
			input {
				border: 1px solid black !important;
			}

            &_textarea {

                textarea {
                    border: 1px solid black !important;
                }
            }
		}
        select + label,
        input[type="text"] + label {
            color: $grey50;
            font-style: italic;
            margin: 0 auto $gap;
        }

        label + * {
            margin-top: $gap / 4;
        }

        li.gfield + li.gfield {
            margin-top: $gap * 2;
        }

        .gfield_required {
            color: $red;
        }
    }
}
.gform_wrapper {
	.gform_body {
		.ginput_container {
			.gfield_checkbox {
				.gfield-choice-input {
					label {
						margin-top: 0rem !important;
					}
				}
				label {
					margin-top: 0rem !important;
				}
			}
			input {
				border: 1px solid $black;
				color: black;
			}
			.name_last, .ginput_right {
				@include break(tablet) {
					margin-top: 0;
				}
			}
		}
	}
	.gform_footer {
		.gform_button {
			margin: 0 !important;
			padding: 0;
			min-height: 3rem;
			min-width: 6rem;
		}
	}
}

.gform_wrapper.gravity-theme .gfield {
    min-width: 100% !important;
    width: 100% !important;
}
