/* +++++++++++++++++++++++++
+++ LAYOUT: TEAM PROFILE +++
+++++++++++++++++++++++++ */

.profile {
	@extend %flex;
	align-items: flex-start;
	padding: $gap * 2 0;

	&-outer {
		&.dark {
			.profile {
				&-name, &-title, &-summary-copy {
					color: $white;
				}
			}
		}
	}

	&-overlay {
		@extend %transition;
		background-color: rgba($black, 0.7);
		height: 100%;
		opacity: 0;
		left: 0;
		position: fixed;
		top: 0;
		visibility: hidden;
		width: 100%;
		z-index: 99;

		&.active-profile {
			opacity: 1;
			visibility: visible;
		}
	}

	&-element {
		@extend %flex;
		@include flexbox(100%);
		align-items: flex-start;
		justify-content: center;
		text-align: center;
		margin: 0 0 $gap * 2;

		@include break(small) {
			@include flexbox(50%);
		}
		@include break(medium) {
			@include flexbox(33.33%);
			margin: 0 0 $gap * 4;
			// margin: 0;
		}
		@include break(large) {
			@include flexbox(25%);
		}
		// &:nth-child(n+4) {
		// 	@include break(medium) {
		// 		margin-top: $gap * 3;
		// 	}
		// 	@include break(large) {
		// 		margin-top: 0;
		// 	}
		// }
		&:nth-child(n + 5) {
			@include break(medium) {
				margin-top: 0;
			}
			@include break(large) {
				margin-top: $gap * 3;
			}
		}
	}

	&-item {
		margin: 0;
		width: 100%;
	}

	&-image {
		@extend %image-hover;
		border-radius: 100%;
		cursor: pointer;
		display: inline-block;
		margin: 0;
		width: 8.75rem;

		img {
			border-radius: 100%;
			margin: 0;
			width: 100%;
			min-height: 8.6rem;
		}
	}
	&-wrap {
		margin: $gap * 2 0 0;
		padding: 0;

		@include break(small) {
			padding: 0 $gap * 1;
		}

		@include break(tablet) {
			padding: 0 $gap * 2;
		}
	}
	&-name {
		font-size: 1.625rem;
		font-family: $font-header;
		color: $pesp-navy;
		font-weight: $medium;
		margin-bottom: 0.5rem;
	}
	&-title {
		font-size: 1.125rem;
		line-height: 1.6em;
		font-weight: normal;
		color: $pesp-navy;
		font-family: $font-body;
		margin-bottom: 1rem;
	}
	&-summary {
		@extend %transition;
		background-color: $pesp-light;
		box-shadow: $shadow;
		color: $black;
		height: 100%;
		margin-top: 0;
		overflow: scroll;
		padding: $gap * 5 $gap * 2 $gap * 2;
		position: fixed;
		right: -90%;
		text-align: left;
		top: 0;
		width: 90%;
		z-index: 999;

		@include break(tablet) {
			padding: $gap * 2.5;
			right: -50%;
			width: 50%;
		}
		@include break(large) {
			padding: $gap * 5;
		}

		&.active-profile {
			right: 0;
		}

		&-copy {
			margin-top: $gap;
			color: $grey50;
			font-size: 1.125rem;
			line-height: 1.6em;
		}

		.profile-summary-close {
			@extend %transition;
			color: $pesp-navy;
			cursor: pointer;
			position: absolute;
			right: $gap * 2;
			top: $gap * 2;
			z-index: 999;
			//...
			border: 2px solid $pesp-primary;
			padding: 1rem 1.125rem;
			width: 7.5rem;
			height: 3.5rem;

			&:after {
				content: "Close";
				display: block;
				position: absolute;
				top: 48%;
				right: 18px;
				transform: translate(0%, -50%);
				font-size: 1.125rem;
				color: $pesp-primary;
				font-family: $font-header;
				letter-spacing: 0.01em;
			}

			&:hover {
				color: $white;
				background-color: $pesp-navy;

				&:after {
					color: $white;
				}
				.icon {
					fill: $white;
				}

				// transform: rotate(90deg);
			}

			//...
			.icon {
				position: relative;
				top: -3px;
				width: 16px;
				fill: $pesp-primary;
			}
		}

		&-inner {
			display: block;
			margin-top: 0;
			position: relative;
			z-index: 11;
			margin-top: 7.5rem;

			.profile-summary-copy {
				color: rgba(25, 34, 56, 0.94);
				margin: 3rem 0rem;
				line-height: 1.6em;
			}

			.profile-name {
				color: $pesp-navy;
				margin-top: 2rem;
				font-weight: $semibold;
				line-height: 1.3em;
				margin-bottom: 1.5rem;

				@include break(small) {
					font-size: 1.5em;
				}
				@include break(medium) {
					margin-top: unset;
					font-size: 2.125rem;
				}
			}
			p {
				color: $black;
				font-size: 0.9em;
				margin-top: 0;
				padding-top: $gap;

				@include break(small) {
					font-size: 1em;
				}
			}
		}
	}
	&-meta {
		margin-top: $gap;

		&-item {
			display: inline-block;
			padding: $gap / 4 0;

			&-link {
				@extend %transition;
				align-items: center;
				display: block;
				height: 40px;
				margin-right: $gap / 2;
				position: relative;
				width: 40px;

				.icon {
					@include centerer(true, true);
				}

				&:hover {
					color: $primary;

					.profile-meta-item-text {
						border-bottom: solid 2px $primary;
					}
				}
				&.mail {
					.icon {
						color: $black;
						height: $gap * 1.2;
						width: $gap * 1.2;
					}
				}
			}

			&-text {
				@extend %transition;
				color: $black;
				margin-left: $gap / 1.5;
			}
		}
	}
}
