/* ++++++++++++++++++++++
+++ LAYOUT: DOWNLOADS +++
++++++++++++++++ */

.download {
	@extend %flex;
	align-items: center;
	justify-content: center;
	padding: $gap * 1.5 0;
	
	&-block {
		@include flexbox(50%);
		margin: $gap 0;
		position: relative;
		
		@include break(medium) {
			@include flexbox(33.33%);
		}
		
		.icon {
			left: 0;
			position: absolute;
			top: 0;
		}
	}
	&-link {
		@extend %transition;
		display: inline-block;
		padding-left: $gap * 2;
		
		&:hover {
			color: $primary;
			text-decoration: underline;
		}
		span {
			font-size: 0.9em;
			text-transform: uppercase;
		}
	}
}