//+++++++++++++++++++++++
//+++ HELPERS: COLORS +++
//+++++++++++++++++++++++

// PESP Brand colours
$pesp-primary: #1445b8;
$pesp-navy: #0e3181;
$pesp-secondary: #192238;
$pesp-yellow: #fff06b;
$pesp-light: #f4f8f9;

// Basic colors
$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #424b5a;
$light-blue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$brown: #795548;
$cream: #f4f3e7;
$white: #ffffff;
$black: #192238;

// Standard greyscale: number = brightness (HSB)
$grey95: #f2f2f2;
$grey90: #e6e6e6;
$grey80: #c4c4c4;
$grey70: #b3b3b3;
$grey60: #999999;
$grey50: #757a88;
$grey40: #666666;
$grey30: #4d4d4d;
$grey20: #333333;
$grey15: #262626;

// Social colors
$dribbble: #ea4c89;
$facebook: #3b5999;
$flickr: #ff0084;
$googleplus: #dd4b39;
$instagram: #e4405f;
$linkedin: #0e3181;
$pinterest: #bd081c;
$snapchat: #fffc00;
$soundcloud: #ff3300;
$tumblr: #34465d;
$twitter: #55acee;
$vimeo: #1ab7ea;
$whatsapp: #25d366;
$youtube: #cd201f;

// Social colors
.dribbble {
	color: $dribbble;
}
.facebook {
	color: $facebook;
}
.flickr {
	color: $flickr;
}
.googleplus {
	color: $googleplus;
}
.instagram {
	color: $instagram;
}
.linkedin {
	color: $linkedin;
}
.pinterest {
	color: $pinterest;
}
.snapchat {
	color: $snapchat;
}
.soundcloud {
	color: $soundcloud;
}
.tumblr {
	color: $tumblr;
}
.twitter {
	color: $twitter;
}
.vimeo {
	color: $vimeo;
}
.whatsapp {
	color: $whatsapp;
}
.youtube {
	color: $youtube;
}
