@-ms-viewport {
    width: device-width;
}

html {
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    width: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, select {
    background: transparent;
    border: 0;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    margin: 0 auto 0;
    padding: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
    -ms-overflow-style: scrollbar;
}
ol, ul {
	list-style: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

// Remove 300ms delay on touch screens
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

fieldset {
  /* Chrome and Firefox setting here
      min-width: min-content */
  min-width: 0;
}
