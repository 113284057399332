/* ++++++++++++++++++++
+++ LAYOUT: WYSIWYG +++
++++++++++++++++++++ */    
    
.wysiwyg {
	margin: 0;
	
	&.center {
		.content {
			margin: 0 auto;		
		}
	}
	
	.content {
		margin: 0;
		
		@include break(medium) {
			width: 66.66%;	
		}		
	}
}