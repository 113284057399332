/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
	background-color: $pesp-secondary;
	color: $white;
	margin-top: 0;
	padding-bottom: 0;
	padding-top: 0;
	z-index: map-get($zindex, footer);

	&-header {
		@extend %flex;
		align-items: center;
		border-top: solid 3px rgba($pesp-light, 0.1);
		justify-content: space-between;
		margin-bottom: $gap * 2;
		padding-top: $gap * 2;

		@include break(medium) {
			margin-bottom: 6.25rem;
			padding-top: 5rem;
		}

		* {
			margin-top: 0;
		}

		.search {
			@include flexbox(100%);

			@include break(tablet) {
				@include flexbox(30%);
			}

			&-input {
				background: rgba($white, 0.1);
				border-color: transparent;
				color: $white;
				font-size: 1em;
				height: auto;
				padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap;
			}
			&-icon {
				height: 24px;
				right: $gap / 1.8;
				width: 24px;
			}
			&-button {
				border-left: $line solid rgba($white, 0.1);
				width: 45px;
			}
		}
	}

	&-logo {
		display: block;
		margin: $gap 0;

		@include break(small) {
			display: inline-block;
			margin: 0;
			width: auto;
		}

		img {
			width: 9.4375rem;
		}
	}

	&-nav {
		&:first-child {
			border-top: 0;
			margin-left: 0;
			padding-top: 0;
		}
		&:nth-child(3) {
			@include break(small) {
				margin-left: 0;
			}
			@include break(medium) {
				margin-left: 2%;
			}
		}

		&-outer {
			@extend %flex;
			* {
				margin-top: 0;
			}
		}

		&.social {
			@include flexbox(100%);
			margin: 0;
			text-align: center;

			@include break(small) {
				@include flexbox(auto);
				text-align: left;
			}
		}

		&-item {
			margin-top: 0;

			.footer-nav-item-title {
				font-size: 1em;
				padding-bottom: $gap;
				font-size: 1.375rem;
				line-height: 1.85625rem;
				letter-spacing: -0.01em;
				font-weight: 600;
			}

			.nav-item-link {
				font-size: 0.9em;
				margin: 0;
				padding: 0 0 $gap / 2 0;

				@include break(large) {
					padding: 0;
					font-size: 1.125rem;
					line-height: 1.8rem;
					margin: 0.5rem 0;
					display: inline-block;
				}
			}
			a {
				&:hover {
					color: $white;
					text-decoration: underline 2px white;
				}
			}

			&.social {
				display: inline-block;
				margin-right: $gap / 1.5;
				text-align: center;

				&:last-child {
					margin-right: 0;
				}

				.nav-item-link {
					@extend %transition;
					background-color: $white;
					border-radius: 100%;
					display: inline-block;
					height: 29px;
					padding: 0;
					position: relative;
					width: 29px;

					&:hover {
						background-color: $secondary;
						.icon {
							color: $white;
						}
					}

					&.instagram,
					&.youtube {
						.icon {
							padding: 2px;
						}
					}

					.icon {
						@include centerer(true, true);
						color: $primary;
						margin: 0;
					}
				}
			}
		}
	}

	p {
		font-size: 0.9em;
	}

	&-details,
	&-nav {
		@include flexbox(100%);
		padding-top: $gap * 1.5;
		margin-bottom: $gap;

		@include break(small) {
			@include flexbox(48%);
			margin-left: 4%;
			padding-top: 0;
		}

		@include break(medium) {
			@include flexbox(16.22%);
			margin-left: 2%;
		}
	}

	&-details {
		margin-right: 0;

		.footer-nav-item-title {
			font-size: 1em;
			padding-bottom: $gap;
		}
		p {
			padding-bottom: $gap;

			a {
				&:hover {
					color: $primary;
				}
			}
		}
	}

	&-credits {
		background-color: $pesp-secondary;
		color: $white;
		padding: $gap * 1.5 0;
		//...

		@include break(tablet) {
			line-height: 2.5rem;
		}
		@include break(medium) {
			margin-top: $gap * 3;
		}

		* {
			margin-top: 0;
		}
		.container {
			display: block;
		}
		p {
			font-size: 0.875rem;
			opacity: 0.6;
		}
	}
}
