/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
	background: $pesp-light;
	color: $pesp-navy;
	margin-top: 0;
	padding-top: 0;

	&-outer {
		display: none;
		overflow: hidden;
		background: $pesp-light;

		@include break(medium) {
			display: none !important;
		}
	}

	&-search {
		padding: $gap;
		//...
		background-color: $pesp-primary;
		.search {
			width: 100%;

			&-input {
				color: $white;
				font-size: 1em;
				height: auto;
				padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap * 4;
				border: 2px solid $white;
				background: none;

				&::placeholder {
					color: white;
				}
			}
			&-icon {
				height: 24px;
				right: $gap / 1.8;
				width: 24px;
			}
			&-button {
				border-left: $line solid rgba($white, 0.1);
				width: 45px;
				background: none;
			}
		}
	}

	&-item {
		border-top: $line solid rgba($white, 0.1);
		position: relative;

		&-link {
			@extend %transition;
			display: block;
			margin: $gap / 3.5 0;
			padding: $gap $gap * 3 $gap $gap;
			text-align: left;

			&:hover {
				background: rgba($white, 0.1);
			}
		}
	}

	&-dropdown {
		background: rgba($black, 0.2);
		display: none;
		overflow: hidden;
		transition: height 500ms ease;
	}

	&-secnav {
		background: lighten($pesp-navy, 2);
		//..
		color: $pesp-yellow;

		&-item {
			border-bottom: $line solid rgba($white, 0.1);
			display: block;

			&:last-child {
				border-bottom: 0;
			}

			.rnav-item-link {
				font-size: 0.9em;
				font-weight: $normal;

				&:hover {
					background: lighten($grey20, 5);
					border-color: $primary;
				}
			}
		}
	}

	&-arrow {
		@extend %transition;
		background-color: transparent;
		border-left: solid 1px rgba($white, 0.1);
		box-shadow: none;
		cursor: pointer;
		padding: $gap;
		position: absolute;
		right: 0;
		top: $gap / 3.5;
		width: auto;

		&:hover {
			background-color: transparent;
			box-shadow: none;
		}

		&.active {
			transform: rotate(-180deg);
		}

		.icon {
			@include transition(transform 0.4s ease);
			display: block;
			fill: $white;
			width: $gap;
		}
	}

	&-button {
		border-top: $line solid rgba($white, 0.1);
		padding: $gap;
	}
}
