/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	background-color: $pesp-light;
	border-radius: $corner;
	margin-bottom: $gap * 3;
	width: 100%;

	@include break(medium) {
		@include flexbox(320px);
		margin: -2em 0 0;
		margin: 0;
		top: calc(#{$header-height} + (#{$gap} * 2));

		.nav-up & {
			top: $gap * 2;
		}
	}
	@include break(large) {
		@include flexbox(400px);
	}

	&-show-button {
		@extend %transition;
		background-image: url("../images/select-arrow.svg");
		background-position: center center;
		background-repeat: no-repeat;
		border: 0;
		display: block;
		height: 30px;
		position: absolute;
		width: 30px;
		background-color: transparent;
		right: 1.1875rem;
		top: 0.8125rem;

		&.active {
			transform: rotate(180deg);
		}
		@include break(medium) {
			display: none;
		}
	}

	&-inner {
		display: none;

		@include break(medium) {
			display: block !important;
		}
	}

	&-block + &-block {
		margin-top: $gap * 3;
	}

	&-title {
		color: $primary;
		display: block;
		font: $bold 1.2em/1.2 $font-header;
		margin: 0;
		padding: 1rem;

		@include break(medium) {
			margin: 0;
			padding: 1.5rem;
			font-size: 1.625rem;
			line-height: 130%;
		}
	}

	&-block {
		position: relative;
	}

	&-nav {
		@include break(medium) {
			border-top: 0;
			margin: 0;
			padding-top: 0;
		}

		&-item {
			padding: 0;
			position: relative;
			border-bottom: 1px solid $grey80;

			.rnav-arrow {
				color: $black;
				display: inline-block;
				float: right;
				margin-top: -$gap * 1.5;
				padding: 0;
				position: relative;
			}

			.sub-list {
				padding: 0 0.75rem 0.75rem;
			}

			&.current {
				.sidebar-nav-list {
					height: auto;
				}
			}

			.subnav_element {
				@extend %text-hover;
				display: block;
				padding: 1.25rem 1.5rem;
				font-size: 1.125rem;
				line-height: 120%;
				font-family: $font-header;
				font-weight: $medium;

				&:hover,
				&.current {
					color: $pesp-navy;
				}
				&.current {
					font-weight: $bold;
				}
			}

			&.current-menu-item {
				.subnav_element {
					color: $pesp-navy;
					font-weight: $bold;
				}

				.sidebar-nav-item {
					.subnav_element {
						color: inherit;
						font-weight: $normal;
					}
				}
			}
		}
		&-list {
			.sub-list {
				& & {
					margin-left: $gap;
				}

				.rnav-arrow {
					margin-top: -$gap * 2;
				}

				.sidebar-nav-item {
					// box-shadow: inset 2px 0 $grey90;
					&:first-of-type {
						margin-top: $gap / 2;
					}
				}

				.sidebar-nav-item-link {
					display: block;
					font-weight: $normal;
					padding: 0.375rem 1.5rem;

					&.current {
						box-shadow: inset 2px 0 currentColor;
						color: $secondary;
						font-weight: $bold;
						margin-left: -$gap;
						padding-left: $gap;
					}

					&:hover {
						color: $pesp-navy;
					}
				}

				.sidebar-nav-item {
					padding-left: $gap;
					border-bottom: 0;

					&.current-menu-item {
						.sidebar-nav-item-link {
							box-shadow: inset 2px 0 currentColor;
						}

						.sidebar-nav-item {
							.sidebar-nav-item-link {
								box-shadow: none;
							}
						}
					}
				}

				.sub-list {
					padding-left: $gap;
					.sidebar-nav-item-link {
						font-size: 0.8em;
					}
				}
			}
		}
	}

	&-meta {
		@extend %flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0;
		position: relative;

		&-block {
			margin: $gap / 1.5 0;

			&.info {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}
				@include break(medium) {
					@include flexbox(100%);
				}
			}
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.tags {
				margin: 0 (-$gap / 4);
			}
			&.image {
				@include flexbox(100%);

				@include break(small) {
					@include flexbox(48%);
				}
				@include break(medium) {
					@include flexbox(100%);
				}

				img {
					@include object-fit(4/5, 100%);
				}
			}

			.smart-date {
				&--time,
				&--date {
					font-weight: 500;
				}
				&--time:after,
				&--date:before {
					content: " ";
					display: block;
				}
			}

			span {
				color: $black;
				font-weight: $semibold;
				font-family: $font-header;
				font-size: 1.375rem;
				line-height: 1.35em;
			}
			&-title {
				@extend %font-content;
				color: $black;
				font-weight: $bold;
				font-family: "Spline Sans";
				font-size: 22px;
				margin: 0 $gap / 4 $gap / 2;
				width: 100%;
			}

			&-tag {
				@extend %transition;
				background-color: $pesp-light;
				// border-radius: 25px;
				color: $black;
				display: inline-block;
				font: $medium 0.8em/1.5 $font-header;
				margin: 0 1rem 1rem 0;
				font-size: 1.125rem;
				line-height: 1.2em;
				padding: 0.75rem;

				&:hover {
					background: $pesp-navy;
					color: $white;
				}
			}
			//..
			&.website {
				a {
					color: $pesp-primary;
					text-decoration: underline;

					&:hover {
						color: $pesp-navy;
					}
				}
			}
			&.share {
				justify-content: flex-start;
			}
			&.downloads {
				background-color: $white;
				color: $black;
				margin-top: $gap * 1.5;
				padding: $gap;
				width: 100%;

				.icon {
					display: inline-block;
					margin: 0 $gap / 3 0 0;
					width: 20px;
				}

				h3 {
					color: $primary;
					font-size: 1.2em;
				}
				.downloads-link {
					@extend %transition;
					display: block;
					font-size: 0.9em;
					padding: $gap / 2 0 0 $gap * 2;
					position: relative;

					&:hover {
						color: $primary;
					}

					.icon {
						left: 0;
						position: absolute;
						top: 4px;
					}
				}
			}
		}
		&-share {
			@extend %flex;
			margin: $gap / 2 0 $gap / 1.5;

			&-title {
				@extend %font-content;
				color: $secondary;
				font-weight: $bold;
				margin: 0 0 $gap / 2;
				width: 100%;
			}

			&-button {
				@extend %flex;
				@extend %transition;
				align-items: center;
				background-color: $primary;
				border-radius: 100%;
				height: 30px;
				justify-content: center;
				margin: 0 $gap / 2 0 0;
				position: relative;
				width: 30px;

				&:hover {
					background-color: $secondary;
				}
				&.twitter,
				&.instagram {
					.icon {
						height: 1.4em;
						width: 1.4em;
					}
				}
				&.whatsapp,
				&.link,
				&.mail {
					.icon {
						height: 1em;
						width: 1em;
					}
				}
				.icon {
					color: $white;
				}
			}
		}
	}
}
//...
.single-post {
	.sidebar {
		background-color: unset;
		&-meta {
			&-block {
				line-height: 3rem;
				margin: 0 0 2.5rem;
				&-link {
					font-weight: $bold;
				}
				span {
					margin: 0 0 1rem;
					display: block;
				}
				p,
				time {
					font-size: 1.125rem;
					line-height: 1.6em;
					color: $grey50;
					display: block;
				}
			}
		}
	}
}
