/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
	background: $body-color;
	display: block;
	margin: 0;

	&-section {
		@extend %flex;
		@extend %section;
		align-items: flex-start;
		margin: auto;
		// max-width: $page-container;
		overflow: visible;
	}

	&-header,
	&-footer {
		text-align: left;
	}

	&-header {
		&-outer {
			background-color: $pesp-primary;
			color: $white;
			padding: 6.25rem 0 0;
			.main-header {
				max-width: 80rem;
				margin: auto;
			}
		}
		&-title {
			padding-bottom: $gap;
		}
		&-summary {
			p {
				font-size: 1.125rem;
				line-height: 1.6em;
				max-width: 48.75rem;
				margin: 0 0 4rem;
			}
		}
	}

	&-section {
		@include break(medium) {
			flex-flow: row wrap;
		}

		> * {
			@extend %flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;

			@include break(medium) {
				flex: 1 66.66%;
				flex-direction: row-reverse;
			}
		}
		> .container {
			column-gap: 3rem;

			@include break(large) {
				column-gap: 5.8125rem;
			}
		}

		&.single {
			> * {
				flex-direction: column-reverse;

				@include break(medium) {
					flex-direction: row-reverse;
				}
			}
			.sidebar {
				margin-top: $gap * 2;

				@include break(medium) {
					margin-top: 0;
				}
			}
		}
	}
}
.funds-listing {
	.main {
		&-header {
			&.container.small {
				max-width: unset;
				max-width: 80rem;
			}
		}
	}
}
