//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
	@extend %transition;
	@extend %font-header;
	// border-radius: $corner;
	display: block;
	font-weight: $normal;
	padding: 1rem 1.25rem;
	text-align: center;
	width: 100%;
	font-size: 1.125rem;
	line-height: 1.2em;
	letter-spacing: 0.01em;

	@include break("small") {
		display: inline-block;
		width: auto;
	}
}

.btn,
.button {
	@extend %button;
	background: $pesp-yellow;
	color: $pesp-secondary;
	font-weight: $medium;

	&:hover {
		background: $pesp-navy;
		color: $white;
	}

	&:active {
		background: $primary-light;
	}
}

.btn-secondary,
.button-secondary {
	@extend %button;
	box-shadow: inset 0 0 0 $line rgba($black, 0.2);

	&:hover {
		box-shadow: inset 0 0 0 $line currentColor;
	}

	&:active {
		background: rgba($black, 0.1);
	}
}

// SPECIFIC BUTTON STYLES
.header,
.footer {
	&-links {
		.button {
			font-weight: $normal;
			position: relative;
			font-size: 1em;
			border: 2px solid transparent;

			&:hover {
				border-color: $pesp-yellow;
			}
		}
	}
	.button {
		display: grid;
		align-content: center;
		height: 3.5rem;
		padding-left: 2rem;
		background-color: transparent;
		color: $pesp-yellow;
		font-weight: $semibold;
		letter-spacing: 0.01em;
		font-size: 1rem;
		padding-left: 2.2rem;

		@include break(large) {
			padding-left: 1rem;
			margin-right: 1rem;
			padding-left: 3rem;
			font-size: 1.125rem;
		}
		&:before {
			@include centerer(false, true);
			left: 1rem;
			content: url("../src/svg/data_icon.svg");
			position: absolute;

			@include break(large) {
				left: 1.3rem;
			}
		}
	}
}
.hero {
	.button {
		background: transparent;
		padding-left: unset;
		padding-right: unset;
		color: $white;
		font-weight: $normal;
		line-height: 21.6px;
		text-decoration: underline !important;

		&:hover {
			opacity: 0.7;
		}
	}
}
.media-block {
	.button {
		background: $pesp-yellow;
		color: $pesp-secondary;
		border: 3px solid $pesp-yellow;
		&:hover {
			background: $pesp-navy;
			color: $white;
			border: 3px solid $pesp-navy;
		}
	}
}
.cards-outer {
	.section-footer {
		.button {
			border: 2px solid $pesp-navy;
			color: $pesp-navy;
			background: transparent;
			padding: 1rem 1.25rem;
			font-weight: $medium;

			&:hover {
				background: $pesp-navy;
				color: $white;
			}
		}
	}
}
.cta {
	.button {
		margin-top: 1.5rem;
		background: $pesp-yellow;
		color: $pesp-secondary;
		padding: 1rem 1.25rem;

		&:hover {
			background: transparent;
			outline: 3px solid $pesp-yellow;
			color: $pesp-yellow;
		}
	}
}
.btnlist-inner {
	.button {
		color: $white;
	}
}
.issue-outer {
	.section-footer {
		.button {
			border: 2px solid $pesp-navy;
			color: $pesp-navy;
			background: transparent;
			padding: 1rem 1.25rem;
			font-weight: $medium;

			&:hover {
				background: $pesp-navy;
				color: $white;
				border: 2px solid $pesp-navy;
			}
		}
	}
}
.content {
	.button {
		margin-top: 1.5rem;
		background: $pesp-yellow;
		color: $pesp-secondary;
		padding: 1rem 1.25rem;
		border: 2px solid $pesp-yellow;
		font-size: 1.125rem;
		font-weight: $medium;

		&:hover {
			background: $pesp-navy;
			border: 2px solid $pesp-navy;
			color: $white;
		}
	}
}
.statistic-outer {
	.section-footer {
		text-align: initial;
		.button {
			background: transparent;
			border: 2px solid $pesp-navy;
			color: $pesp-navy;
			font-weight: $medium;

			&:hover {
				background: $pesp-yellow;
				border: 2px solid $pesp-yellow;
				color: $pesp-navy;
			}
		}
	}
}
.profile-outer {
	.section-footer {
		text-align: center;
		.button {
			background: transparent;
			border: 2px solid $pesp-navy;
			color: $pesp-navy;
			font-weight: $medium;

			&:hover {
				background: $pesp-yellow;
				border: 2px solid $pesp-yellow;
				color: $pesp-navy;
			}
		}
	}
}
.issue-inner {
	.section-button-footer {
		.button {
			background-color: $pesp-light;
			border: 2px solid $pesp-light;
			color: $pesp-primary;
			font-weight: $medium;

			&:hover {
				background: $pesp-primary;
				border: 2px solid $pesp-primary;
				color: $pesp-light;
			}
		}
	}
}
.search-button {
	background-color: $pesp-secondary;
	@include break(medium) {
		background-color: $white;
	}
}

.search-filter-submit {
	position: relative;
}
